import React from 'react';
import styled from 'styled-components';
import Carousel_ from '@components/Carousel';

const BasicCarouselCard = styled.div`
  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    &.swiper-slide-prev,
    &:first-of-type {
      margin-left: 20px;
    }

    &:first-of-type.swiper-slide-prev {
      margin-left: 40px;
    }
  }

  @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
    max-width: 270px;

    &.swiper-slide-prev,
    &:first-of-type {
      margin-left: 15px;
    }

    &:first-of-type.swiper-slide-prev {
      margin-left: 30px;
    }
  }
`;

export const CarouselItem = styled(BasicCarouselCard)`
  max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : `407px`)};
`;

const Carousel = props => {
  return (
    <Carousel_
      listItems={props.listItems}
      spaceBetween={props.spaceBetween}
      inverted={props.inverted}
      itemPerView={props.itemPerView}
      loopSlider={props.loopSlider}
    >
      {props.children}
    </Carousel_>
  );
};

export default Carousel;
